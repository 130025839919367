export const environment = {
  production: true,
  protocol: 'https:',
  baseUrls: function() {
    const hasWindow = typeof window !== 'undefined';
    const protocol = hasWindow ? window.location.protocol : this.protocol;
    const apiUrl = hasWindow ? (protocol + '//api-stg.matboardandmore.com/') : 'http://localhost:8080/';
    const site = protocol + '//stg.matboardandmore.com';
    return {
      site: site,
      serverSideSite: protocol + '//localhost',
      cms: protocol + '//cms-stg.matboardandmore.com/',
      api: apiUrl,
      preview: protocol + '//api-stg.matboardandmore.com/preview?',
      previewShare: site + 'share/'
    };
  },
  sizeLimits: {
    opening: {min: 1, max: 38},
    outer: {min: 4, max: 40, secondaryMax: 32}
  },
  easelLimits: {
    code: 'EASEL',
    availableSizes: ['5x7','7x5','8x10','10x8','11x14','14x11'],
    mouldingWidth: 1.25,
    fallbackBackingCode: 'FOAM'
  },
  glazingLimits: {
    shorterDim: 16,
    longerDim: 20,
    thickness: 0.05
  },
  minMargins: {
    ui: 36,
    minUnder: 0.5,
    minOver: 1
  },
  hangerConditions: {
    sawtoothSizeLimit: 20,
    metalCategoryName: 'Metal'
  },
  vGroove: {
    disallowedMats: '9632,SRM989,SRM988,9877,6989',
    offset: {
      openingEdgeMinOffset: 0.5,
      openingEdgeMaxOffset: 1.0,
      outerEdgeMinOffset: 0.5,
      vGrooveOffsetIncrement: 0.25
    }
  },
  disablePreventNavigation: false,
  trustPilotUrl: 'https://api.trustpilot.com/v1/business-units/554bccfb0000ff00057f45b9',
  trustPilotApiKey: 'bOmkVrB1sWa02Iw2XzAcrWaeIquGvKB3',
  minBackingThicknessForFrames: 0.125,
  qtySuggestions: [5, 10, 25, 50, 100, 500, 1000],
  activePaymentProcessor: 'SQUARE',
  acceptJS: {
    scriptUrl: 'https://jstest.authorize.net/v1/Accept.js',
    publicKey: '4vRsU4D2ntHC88tQK55MaPj3Zrt3cVm8kU79QEm6xLM5QhSR2cmXXB4246M9kekv',
    loginId: '7c8Sd5JqJ6'
  },
  squareSDK: {
    scriptUrl: 'https://sandbox.web.squarecdn.com/v1/square.js',
    locationId: 'LB2Q039SFCJ4F',
    applicationId: 'sandbox-sq0idb-bxlKMusdVKCS9TA4cTHRCQ'
  },
  complicatedCornerMinOpeningSize: {
    width: 3,
    height: 3
  },
  defaultParts: {
    topMat: 'SRM3297',
    bottomMat: 'SRM921A',
    moulding: 'FR26099'
  },
  wc: {
    defaultProducts: {
      mat: '4554',
      frame: '4556',
      sample: null
    }
  },
  googlePlacesApiKey: 'AIzaSyCH6CKFqxUHKh_ou6v1dAXhzO5XneURR24',
  bugSnagApiKey: null
};
